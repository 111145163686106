<script>
import Banner from "../components/Banner.vue";

export default {
  name: "LearnMore",
  components: {
    Banner
  }
};
</script>

<template>
  <div>
    <Banner :bannerSrc="require('@/assets/banner/01.jpg')" />
    <div class="padding-comm-warp more">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        class="more_breadcrumb"
      >
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content">
        <!--        艺术银行简介-->
        <div class="content_item ">
          <div class="more_title_warp ">
            <div class="title_div_warp">
              <div class="title">
                艺术银行简介
              </div>
              <div class="title_but_warp" />
            </div>
          </div>

          <div class="content">
            中国艺术银行是在中华人民共和国澳门特别行政区注册的一家在境内外综合经营文化、艺术品交易、金融租赁、保险业务、商业银行、基金管理、进出口贸易等综合性国际金融集团，其拥有位于中国香港、澳门的重大投资基金，在投资领域拥有相当的能力和专业经验。
          </div>
        </div>

        <!--        战略定位-->
        <div class="content_item ">
          <div class="more_title_warp ">
            <div class="title_div_warp">
              <div class="title">
                战略定位
              </div>
              <div class="title_but_warp" />
            </div>
          </div>

          <div class="content">
            开放 互联 对等 全球化 去中心化<br />
            中国在未来将实现由艺术资源大国转变成艺术经济大国，除了具有强大的艺术创新能力外，一个以艺术品资本市场为基础的，具有强大资源配置能力和财富创造<br />
            效应的现代艺术品交易体系是不可缺的。<br />
            中国艺术银行有效促进中国艺术品资本市场的形成和优化艺术资源配置，成为推动我国艺术经济发展与结构形成的重要平台。在日益开放的国际经济体系中，经济文化全球化是大势所趋，配合中国国家战略在开放中保持文化安全与艺术经济秩序的基础上，互惠互利地促进中国艺术经济的发展。
          </div>
        </div>

        <!--        战略目标-->
        <div class="content_item ">
          <div class="more_title_warp ">
            <div class="title_div_warp">
              <div class="title">
                战略目标
              </div>
              <div class="title_but_warp" />
            </div>
          </div>

          <div class="content">
            建立具有良好流动性和透明度，具有足够宽度和深度的中国艺术品资本市场，以及以此为基础的现代艺术品交易体系，是中国艺术银行的重要战略目标。 其设计满足:最大限度地利用对外开放的利益促进中国艺术品资本市场和整个中国艺术品市场的发展，弘扬民族精神，增强国家软实力。最大限度地发挥中国艺术品资本市场对国家金融安全起到不可替代的作用。
          </div>
        </div>

        <!--        战略任务-->
        <div class="content_item ">
          <div class="more_title_warp ">
            <div class="title_div_warp">
              <div class="title">
                战略任务
              </div>
              <div class="title_but_warp" />
            </div>
          </div>

          <div class="content">
            培育中国艺术金融市场体系<br />
            中国艺术品市场的兴起、发展与高潮是具有一定的社会政治与文化经济背景;当下，中国艺术品市场体系的培育也同样有其自身的背景环境，现正处于深度调整期。<br />
            (一)艺术品投资已经成为继股市、楼市之后的第三投资热点，其巨大的价值空间等待发掘;<br />
            (二)中国艺术品市场呈现金融化的发展趋势代表着大资本和新理念的介入，将使中国艺术品市场得到更大的成长空间;<br />
            (三)当前中国艺术品市场存在种种弊端，如:热衷投资与炒作，价格离谱;市场交易不透明，大量洗钱现象滋生，暗箱操作猖獗;缺乏权威科学的保真、估价体系;因此，完善中国艺术品市场创新体系，精心地建立标准与模式，提高市场专业化、产业化，使市场政策配套、监督到位，中国艺术银行才会高效有序发展。
          </div>
        </div>

        <div class="conent_parting_line" />

        <!--        金融功能-->
        <div class="content_item ">
          <div class="more_title_warp ">
            <div class="title_div_warp">
              <div class="title">
                金融功能
              </div>
              <div class="title_but_warp" />
            </div>
          </div>

          <div class="content">
            创新新金融一全球最具爆发力的银行<br />
            创新的中国艺术银行，包含了传统银行业务及艺术品金融业务，更强调“以人为本"的核心金融服务，强调对艺术家自身的金融扶持与艺术孵化。<br />
            、中国艺术银行的部分金融业务<br />
            (一)跨境汇兑、旅行支票、信用卡、银行卡业务;<br />
            (二)艺术品交易业务;<br />
            (三)艺术品鉴定、经济担保和信用见证业务;<br />
            (四)艺术管理业务、结构解决业务、艺术平台业务:<br />
            (五)外汇存款、汇款、放款、担保业务;<br />
            (六)贸易、非贸易结算;<br />
            (七)外市票据的承兑和贴现、买卖或代理买卖及外币有价证券:<br />
            (八)发行艺术基金、信托等业务;<br />
            (九)针对个人资产提供财产管理和增值、财产转移、信托、财产保护等服务;(十)提供信用证服务及担保;<br />
            (十一)提供保管箱服务;<br />
            (十二)买卖和保管贵重金属、宝石、艺术品、收藏品等贵重物品;<br />
            (十三)从事不违反银行法规的中国人民银行允许的其它工作、服务;(十四)在此基础上商业银行可向人行申请投资银行许可证，办理投行业务;<br />
            、中国艺术银行金融功能设计(一)全球精神财富储备中心，艺术品、收藏品、文化产品、智业产品都可以进行挂单交易及合作;(二)亚洲艺术品金融中心，汇聚亚洲最活跃的艺术品金融机构及客商进行艺术品金融化合作;(三)全球艺术银行仓库，设立第三方艺术银行仓储，为全球艺术品银行、艺术银行、艺术基金、拍卖公司、典当公司、艺术展览、画廊、艺术家、匠人、收藏家等等提供全球最先进的立体020仓储服务;(四)离岸银行功能:通过对艺术品、文化创意商品、收藏品等具有一定保值增值能力的有价物品，进行有时效期限约定的价值评估，(五)离岸人民币结算业务;推动人民币和新兴经济体国家联合，利用区块链记帐的技术发行超主权数字货币，主要以人民币提供信用支撑和零费率总换，更好的提升人民币跨境支付的货币品质。<br />
            (六)全球易货票据结算中心:专业处理两国之间根据记账或支付(清算)协定，凭装运单证进行结汇;(七)全球易货中央仓库，直接服务于艺术银行所有支属，并同时设立物联网在线仓库(虚拟仓库)，艺术银行可以授权全球有需求的国家及商业集团当地设立实体全球易货中央仓库某国某地区中心，银行进行全程物联网监控进出及库存数量等;<br />
            (/)国际艺术金融及艺术文化机构信用评级:<br />
            (九)全球24小时在线易货结算交易系统平台:<br />
            (十)中国艺术银行达到大客户级别的客户服务，包含会籍管理、定制服务、业务受理等服务;<br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../styles/index.scss";
@import "./index.scss";
@import "index";

.more_breadcrumb {
  border-bottom: 1px solid $theme-red;
  padding: 40px 0 10px;
  font-size: 1.2rem;
}
.content {
  margin: 30px 0 46px;
}

// 标题
.more_title_warp {
  display: flex;
  justify-content: center;
  padding-top: 56px;
  .title_div_warp {
    text-align: center;
  }
  .title {
    height: 50px;
    font-weight: 400;
    font-size: 1.6rem;
    color: $theme-red;
    line-height: 54px;
    font-style: normal;
  }
  .title_but_warp {
    background: url("../../assets/home/business_bg.png") no-repeat;
    background-size: cover; /* 背景图片覆盖整个容器 */
    background-position: center; /* 背景图片居中 */
    height: 8px;
  }
}

// 内容
.content {
  font-family: SiYuanSongTiRegular;
  line-height: 30px;
}
.conent_parting_line {
  border-bottom: 1px solid $theme-red;
  padding: 30px 0;
}
</style>
